import _DatabaseModel, { i18n } from "./_DatabaseModel";

class Service extends _DatabaseModel {
  static model = "service";
  static showSize = 8;
  static reload_loadable = "role_issue_state_transition";
  static editDialogComponent = "services-edit-dialog";
  static createNewObject = { object: { planned_revisions: 1 } };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    {
      textKey: "service.label.service_type",
      value: "service_type",
      translationPrefix: "service.service_types",
    },
    { textKey: "form.label.archived_at", value: "discarded_at" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "duplicate",
      "createIssue",
      "generateWorkflowDiagram",
      "manageSubscription",
      "permittedPersons",
      "activity",
      "archive",
    ],
    reloadingModels: [
      "issue",
      "template_assignment",
      "service_issue_states",
      "service_issue_priorities",
    ],
  };

  static filter = {
    parts: {
      fields: [
        "name",
        "contract_id",
        "rule_id",
        "price_list_id",
        "service_type",
        "persistence",
      ],
      extended_fields: ["description", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["link_to_document", "archive_multiple"];

  static service_types = ["reactive", "periodic"];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: i18n.t(`service.service_types.${object.service_type}`),
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "service.label.service_type",
        value: i18n.t(`service.service_types.${object.service_type}`),
        type: "plain",
      },
      {
        labelKey: "contract.contract",
        value: object.contract,
        type: "link",
        to: "contracts",
      },
      {
        labelKey: "price_list.price_list",
        value: object.price_list,
        type: "link",
        to: "price_lists",
      },
      {
        labelKey: "rule.rule",
        value: object.rule,
        type: "link",
        to: "rules",
        condition: object.service_type === "periodic",
      },
      {
        labelKey: "service.label.automated_planning",
        value: object.automated_planning,
        type: "boolean",
        condition: object.service_type === "periodic",
      },
      {
        labelKey: "service.label.planned_revisions",
        value: object.planned_revisions,
        type: "plain",
        condition: object.service_type === "periodic",
      },
      {
        labelKey: "issue_state.issue_state",
        value: object.open_issue_state,
        type: "state",
        condition: object.service_type === "periodic",
      },
      {
        labelKey: "issue_state.label.plan_issue_state",
        value: object.plan_issue_state,
        type: "state",
      },
      {
        labelKey: "issue_state.label.close_issue_state",
        value: object.close_issue_state,
        type: "state",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.archived_at",
        value: object.discarded_at,
        type: "date_time",
        condition: object.discarded_at,
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "asset.assets",
      permission: "services_tab_assets",
      component: "AssetsOnServiceTab",
      input_data: (context) => context.object,
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "service.label.workflow",
      permission: "services_workflow_tab",
      component: "WorkflowTab",
      input_data(context) {
        return {
          service_type: context.object.service_type,
          template_assignments: context.object.template_assignments?.filter(
            (ta) => ta.for_checklist === false
          ),
          definition: context.object.definition,
        };
      },
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "template.templates",
      permission: "services_tab_templates",
      component: "TemplatesAssignmentsTab",
      input_data: (context) => context.object.template_assignments,
      parameters: { entity_type: "Service" },
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "person.persons",
      permission: "services_tab_persons",
      component: "ServicePersonsTab",
      input_data: (context) => context.object,
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "document.documents",
      permission: "services_tab_documents",
      component: "DocumentsTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "issue.issues",
      permission: "services_tab_issues",
      component: "IssuesTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "sla.sla",
      permission: "services_tab_sla",
      component: "ServiceSlaTab",
      disabled: (context) => context.dataShow_disableUpdate,
      condition: (context) => context.object.service_type === "reactive",
    },
  ];
}

export default Service;
